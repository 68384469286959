const SIGN_UP_CTA_DISMISSAL_TIME_KEY = "lastSignUpDismissalTime";
const SLEEP_PERIOD = 2 * 60 * 1000; // 2 minutes

import { useCallback } from "react";
import { setFunnelModalConfig } from "../../../components/funnel/FunnelModal";
import { FUNNEL_TYPES } from "../../../components/funnel/utils/contants";
import { useUserData } from "../../../hooks/useUserData";
import { useGetNavigationContext } from "../../../hooks/useGetNavigationContext";
import { FunnelContextArea } from "../../../components/funnel/types";
import { ImmutableObject } from "@hookstate/core";
import { Release } from "../../../types";

type UseAccountCreationCTAProps = {
  onCompleteOrDismiss: (...args: any[]) => void;
  releaseData?: ImmutableObject<Release> | undefined;
};

const useAccountCreationCTA = ({
  onCompleteOrDismiss,
  releaseData,
}: UseAccountCreationCTAProps) => {
  const { userData } = useUserData();

  const navigationContext = useGetNavigationContext({
    contextArea: FunnelContextArea.startNextReleaseButton,
  });

  const triggerAccountCreationCTA = useCallback(
    (...args: any[]): void => {
      const shouldTriggerAccountCreationCTA = shouldTriggerAccountCreation();

      // Check if logged in and if sleep period has passed
      if (!userData && shouldTriggerAccountCreationCTA) {
        setFunnelModalConfig({
          options: {
            modalContext: FunnelContextArea.startNextReleaseButton,
            onAuthenticated: () => {
              // Excecute action after user has authenticated
              onCompleteOrDismiss(...args);
            },
            onClose: () => {
              // Store time of dismissal and execute action
              localStorage.setItem(
                SIGN_UP_CTA_DISMISSAL_TIME_KEY,
                Date.now().toString(),
              );

              onCompleteOrDismiss(...args);
            },

            navigationContext,
          },
          type: FUNNEL_TYPES.signup,
        });
      } else {
        onCompleteOrDismiss(...args);
      }
    },
    [userData, releaseData, onCompleteOrDismiss],
  );

  const shouldTriggerAccountCreation = useCallback(() => {
    const dismissedAt = localStorage.getItem(SIGN_UP_CTA_DISMISSAL_TIME_KEY);
    const now = Date.now();
    return !dismissedAt || now - parseInt(dismissedAt) > SLEEP_PERIOD;
  }, [SLEEP_PERIOD]);

  return { triggerAccountCreationCTA };
};

export default useAccountCreationCTA;
